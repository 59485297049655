  <template>
  <div class="container">
    <vue-html2pdf
      v-if="patientProfile"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="HISTORIAL SOCIO ECONOMICO"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="740px"
      ref="html2Pdf"
    >
    <pdfSocial
      slot="pdf-content"
      :patientId="patientId"
      :socioeva2="socioeva"
      :fecha="dateSocialAppointment"
      :patientInfo="patientInfo"
      :patientProfile="patientProfile"
    />
  </vue-html2pdf>
  <el-button
    type="primary"
    icon="el-icon-printer"
    size="mini"
    @click="downloadPdf"
  >IMPRIMIR
  </el-button>
    <div class="content-date">
      <div
        style="
          background-color: #6A41BD;
          height: 80px;
          display: flex;
          justify-content: center;
          border-radius: 5px;
          align-items: center;
        "
      >
        <img
          src="../assets/login-bg.png"
          alt="logo Codet"
          style="width: 75px; height: 75px"
        >
      </div>
      <div class="medical-note">
        <h3>{{ $dateString(dateSocialAppointment) }}</h3>
      </div>
    </div>
    <div class="sections" v-if="haveData">
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            DATOS GENERALES DEL PACIENTE
          </el-divider>
        </p>
        <div class="row">
          <div>
            <p class="title">Nombre</p>
            <p class="content">{{ patientInfo.fullName }}</p>
          </div>
          <div>
            <p class="title">Sexo</p>
            <p class="content">{{ patientInfo.sex === 'Femenino' ? 'Mujer' : 'Hombre'  }}</p>
          </div>
          <div>
            <p class="title">Edad</p>
            <p class="content">{{ $ageNumber(patientInfo.birthdate)  }}</p>
          </div>
          <div>
            <p class="title">Ocupación</p>
            <p class="content">
              {{ patientInfo.occupation == null ? 'Sin registro' : patientInfo.occupation }}
            </p>
          </div>
        </div>
        <div class="row">
          <div>
            <p class="title">Domicilio</p>
            <p class="content">
              {{ patientProfile.address == null ? '' : patientProfile.address }},
              {{ patientProfile.city == null ? '' : patientProfile.city }},
              {{ patientProfile.state == null ? '' : patientProfile.state }},
              {{ patientProfile.country == null ? '' : patientProfile.country }}
            </p>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            DATOS INFORMATIVOS DEL BENEFICIARIO
          </el-divider>
        </p>
        <div class="row">
          <div>
            <p class="title">
              Motivo de la evaluación
            </p>
            <p class="content">
              {{socioeva.motivoEvaluacion}}
            </p>
          </div>
          <div>
            <p class="title">
              TIEMPO DE RESIDENCIA EN TIJUANA
            </p>
            <p class="content">
              {{socioeva.residenceTimeAtTijuana}}
            </p>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            LINEA DE BIENESTAR
          </el-divider>
        </p>
        <div class="row">
          <div>
            <p class="title">
              ESCOLARIDAD
            </p>
            <p class="content">
              {{socioeva.academicDegree}}
            </p>
          </div>
          <div>
            <p class="title">
              CONDICIONES DE LA VIVIENDA
            </p>
            <p class="content">
              {{socioeva.houseCondition}}
            </p>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            CARACTERÍSTICAS DE LA VIVIENDA
          </el-divider>
        </p>
        <div class="row">
          <div>
            <p class="title">
              PAREDES
            </p>
            <p class="content">
              {{socioeva.houseWalls}}
            </p>
          </div>
          <div>
            <p class="title">
              TECHO
            </p>
            <p class="content">
              {{socioeva.houseRoof}}
            </p>
          </div>
          <div>
            <p class="title">
              PISO
            </p>
            <p class="content">
              {{socioeva.houseFloor}}
            </p>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            CUARTOS DE LA VIVIENDA
          </el-divider>
        </p>
        <div class="row">
          <div>
            <p class="title">
              CUARTOS
            </p>
            <p class="content">
              {{rooms}}
            </p>
          </div>
          <div v-if="socioeva.bathroom.length > 0">
            <table class="table-history-social">
              <tr>
                <th>Baño</th>
                <th>Pozo</th>
                <th>Regadera</th>
                <th>Fosa</th>
                <th>WC</th>
              </tr>
              <tr v-for="(bath, idx) in socioeva.bathroom" :key="idx" >
                <td>{{ idx + 1 }}</td>
                <td>{{bath.pozo ? 'Sí' : 'No'}}</td>
                <td>{{bath.regadera ? 'Sí' : 'No'}}</td>
                <td>{{bath.fosa ? 'Sí' : 'No'}}</td>
                <td>{{bath.wc ? 'Sí' : 'No'}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            SERVICIOS DE LA VIVIENDA
          </el-divider>
        </p>
        <p class="content">
          {{services}}
        </p>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            MUEBLES Y ELECTRODOMÉSTICOS
          </el-divider>
        </p>
        <p class="content">
          {{houseStuff}}
        </p>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            CUENTA CON AUTO PROPIO
          </el-divider>
        </p>
        <table class="table-history-social" v-if="tableCars.length > 0">
          <tr>
            <th>Automóvil</th>
            <th>Modelo</th>
            <th>Año</th>
            <th>Placas</th>
          </tr>
          <tr v-for="(car, idx) in tableCars" :key="idx" >
            <td>{{ idx + 1 }}</td>
            <td>{{ car.model }}</td>
            <td>{{ car.year }}</td>
            <td>{{ car.plates }}</td>
          </tr>
        </table>
        <p v-else class="content">No</p>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">INGRESOS MENSUALES EN PROMEDIO</el-divider>
        </p>
        <p class="sub"/>
        <table class="table-history-social">
          <tr>
            <th>NOMBRE</th>
            <th>PARENTESCO</th>
            <th>OCUPACIÓN</th>
            <th>EDAD</th>
            <th>INGRESOS MENSUALES PROMEDIO</th>
          </tr>
          <tr v-for="contributor in socioeva.familyCore" :key="contributor.id">
            <td>{{ contributor.fullname }}</td>
            <td>{{ contributor.relationship }}</td>
            <td>{{ contributor.occupation }}</td>
            <td>{{
              contributor.age === 'Sin datos' ? $ageNumber(contributor.birthdate) : contributor.age
            }}</td>
            <td>{{ normalizeCurrency(contributor.monthlyContribution) }}</td>
          </tr>
        </table>
        <div class="row">
          <p class="totales">
            TOTAL DE INGRESOS: <span>{{ normalizeCurrency(totalContribution) }}</span>
          </p>
        </div>
      </div>
      <div class="section expences">
        <p class="sec-title">
          <el-divider content-position="left">
            EGRESOS MENSUALES EN PROMEDIO
          </el-divider>
        </p>
        <table class="table-history-social">
          <tr>
            <th>Agua</th>
            <th>Luz</th>
            <th>Teléfono / internet</th>
            <th>Gas</th>
            <th>Alimentación</th>
            <th>Limpieza del hogar</th>
          </tr>
          <tr>
            <td>{{ normalizeCurrency(socioeva.expenseWater) }}</td>
            <td>{{normalizeCurrency(socioeva.expenseLight)}}</td>
            <td>{{ normalizeCurrency(socioeva.expensePhone) }}</td>
            <td>{{ normalizeCurrency(socioeva.expenseGas) }}</td>
            <td>{{ normalizeCurrency(socioeva.expenseFood) }}</td>
            <td>{{normalizeCurrency(socioeva.expenseHouseSupplies)}}</td>
            <td/>
            <td/>
          </tr>
        </table>
        <table class="table-history-social" style="margin-top: 20px;">
          <tr>
            <th>Renta</th>
            <th>Celular</th>
            <th>Transporte</th>
            <th>Salud</th>
            <th>Educación</th>
            <th>Tarjeta de crédito</th>
            <th>Otros</th>
          </tr>
          <tr>
            <td>{{ normalizeCurrency(socioeva.expenseRent) }}</td>
            <td>{{ normalizeCurrency(socioeva.expenseCellPhone) }}</td>
            <td>{{normalizeCurrency(socioeva.expenseTransport)}}</td>
            <td>{{ normalizeCurrency(socioeva.expenseHealth) }}</td>
            <td>{{ normalizeCurrency(socioeva.expenseEducation) }}</td>
            <td>{{normalizeCurrency(socioeva.expenseCreditCard)}}</td>
            <td>{{ normalizeCurrency(socioeva.expenseOther) }}</td>
            <td/>
            <td/>
          </tr>
        </table>
        <div>
          <p class="totales"  style="margin-bottom: 1px;">
            TOTAL DE EGRESOS: <span>{{normalizeCurrency(totalExpenses)}}</span>
          </p>
          <p class="totales"  style="margin-top: 1px;">
            REMANENTE O DEUDA: <span>{{normalizeCurrency(totalContribution - totalExpenses)}}</span>
          </p>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            CUALITATIVO
          </el-divider>
        </p>
        <div style="margin-left: 40px;">
          <p class="subtitle">
            DESCRIPCIÓN DE LA SITUACIÓN FAMILIAR
          </p>
          <p class="content" id="html-p">
            <span v-html="socioeva.familySituationDescription"  id="html-p" />
          </p>
        </div>
        <div style="margin-left: 40px;">
          <p class="subtitle">
            COMENTARIOS DE LA TRABAJADORA SOCIAL
          </p>
          <p class="content">
            <span v-html="socioeva.comments"/>
          </p>
        </div>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
           EVALUACIÓN DE LA TRABAJADORA SOCIAL
          </el-divider>
        </p>
        <p class="content">
          {{socioeva.evaluationResult}}
        </p>
      </div>
      <div class="section"  v-if="socioeva.surgery.length > 0">
        <p class="sec-title">
          <el-divider content-position="left">
            Cirugías
          </el-divider>
        </p>
        <table class="table-history-social" style="margin-top: 20px;">
          <tr>
            <th>Ojo</th>
            <th>Especialidad</th>
            <th>Tipo</th>
            <th>Costo</th>
            <th>Precio</th>
            <th>Moneda</th>
          </tr>
          <tr v-for="surgery in socioeva.surgery" :key="surgery.id">
            <td>{{ surgery.eye == 'RIGHT' ? 'DERECHO' : 'IZQUIERDO' }}</td>
            <td>{{ surgery.specialty }}</td>
            <td>{{ surgery.type }}</td>
            <td>{{ surgery.price === 0 ? 'Sin costo' : 'Con costo' }}</td>
            <td>{{ surgery.price || '$0' }}</td>
            <td>{{ surgery.currency || 'Sin costo' }}</td>
          </tr>
        </table>
      </div>
      <div class="section">
        <p class="sec-title">
          <el-divider content-position="left">
            AUTORIZÓ
          </el-divider>
        </p>
        <p class="content">
          {{ socioeva.creator ? socioeva.creator.firstName : '' }}
          {{ socioeva.creator ? socioeva.creator.lastName : '' }}
        </p>
      </div>
    </div>
    <div v-else class="no-data" :style="{height: height}">
      <h3>Aun no se lleva a cabo la evaluación</h3>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import VueHtml2pdf from 'vue-html2pdf';
import ApiPrinter from '../API/pdfPrint';
import pdfSocial from './PdfSocial';
import patientAPI from '../API/comunidad/patient';

export default {
  name: 'SocioEconomic',
  data() {
    return {
      tableCars: [],
      evaluationId: '',
      patientInfo: [],
      patientProfile: [],
      appointmentId: '',
      dateSocialAppointment: '',
      patientId: '',
      socioeva: {
        familyCore: [],
        familyWide: [],
      },
      haveData: false,
      height: !this.haveData ? '250px' : '450px',
    };
  },
  components: {
    VueHtml2pdf,
    pdfSocial,
  },
  computed: {
    ...mapFields(['controlValue']),
    totalContribution() {
      let acct = 0;
      for (let i = 0; i < this.socioeva.familyCore.length; i++) {
        const element = this.socioeva.familyCore[i];
        acct += parseFloat(element?.monthlyContribution || 0);
      }
      for (let i = 0; i < this.socioeva.familyWide.length; i++) {
        const element = this.socioeva.familyWide[i];
        acct += parseFloat(element?.monthlyContribution || 0)
          + parseFloat(element?.otherContribution || 0);
      }
      return acct;
    },
    totalExpenses() {
      let acct = 0;
      const keys = [
        'expenseWater',
        'expenseLight',
        'expensePhone',
        'expenseCellPhone',
        'expenseGas',
        'expenseFood',
        'expenseHouseSupplies',
        'expenseHealth',
        'expenseEducation',
        'expenseRent',
        'expenseTransport',
        'expenseCreditCard',
        'expenseOther',
      ];
      for (let i = 0; i < keys.length; i++) {
        const element = this.socioeva[keys[i]];
        if (element) {
          acct += parseFloat(element);
        }
      }
      return acct;
    },
    services() {
      const services = [];
      const servicesMap = {
        serviceLight: 'Luz',
        serviceSewerSystem: 'Drenaje y alcantarillado',
        serviceCableTv: 'Televisión por cable',
        servicePhone: 'Teléfono fijo',
        serviceInternet: 'Internet',
        serviceAsphaltStreet: 'Pavimiento en las calles',
        servicePublicWater: 'Agua Pública',
        serviceOther: 'Otro',
      };
      const keys = Object.keys(servicesMap);
      keys.forEach((key) => {
        if (this.socioeva[key]) {
          services.push(servicesMap[key]);
        }
      });
      return services.join(', ') || 'Sin servicios';
    },
    rooms() {
      const rooms = [];
      const roomsMap = {
        houseRoomsLivingRoom: 'Sala',
        houseRoomsDiningRoom: 'Comedor',
        houseRoomsKitchen: 'Cocina',
        houseRoomsBedroom: 'Recámara',
        houseRoomsStudyingRoom: 'Estudio',
        houseRoomsCourtyard: 'Patio',
        houseRoomsOther: 'Otro',
      };
      const keys = Object.keys(roomsMap);
      keys.forEach((key) => {
        if (this.socioeva[key]) {
          let room = roomsMap[key];
          if (key === 'houseRoomsBedroom') {
            const value = this.socioeva.houseRoomsBedroom;
            const suffix = value > 1 ? 's' : '';
            room = `${value} ${room}${suffix}`;
          }
          rooms.push(room);
        }
      });
      return rooms.join(', ') || 'Sin datos';
    },
    houseStuff() {
      const houseStuff = [];
      const houseStuffMap = {
        houseStuffTV: 'Televisión',
        houseStuffFridge: 'Refrigerador',
        houseStuffStove: 'Estufa',
        houseStuffDishWasher: 'Lava platos',
        houseStuffMicrowaveOven: 'Horno de Microondas',
        houseStuffBlender: 'Licuadora',
        houseStuffWashingMachine: 'Lavadora',
        houseStuffDryer: 'Secadora',
        houseStuffComputer: 'Computadora o Ipad',
        houseStuffDiningRoomSet: 'Comedor',
        houseStuffLivingRoomSet: 'Sala',
        houseStuffBedroomSet: 'Recámara',
      };
      const keys = Object.keys(houseStuffMap);
      keys.forEach((key) => {
        if (this.socioeva[key]) {
          houseStuff.push(houseStuffMap[key]);
        }
      });
      return houseStuff.join(', ') || 'Sin datos';
    },
  },
  methods: {
    async downloadPdf() {
      // const data = {
      //   patient: {
      //     ...this.patientInfo,
      //     ...this.patientProfile,
      //   },
      //   socioeva: this.socioeva,
      //   totalContribution: this.totalContribution,
      //   totalExpenses: this.totalExpenses,
      //   services: this.services,
      //   rooms: this.rooms,
      //   houseStuff: this.houseStuff,
      // };
      // const request = await ApiPrinter.getPrintSocioEconomic(data, this.dateSocialAppointment);
      // if (!(this.validateControlValue())) return;

      this.$refs.html2Pdf.generatePdf();
      // },
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        this.controlValue.pdfQuality = 2;

        return false;
      }

      if (!this.controlValue.paginateElementsByHeight) {
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }

      const paperSizes = 'a4';

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        this.controlValue.pdfFormat = 'a4';

        return false;
      }

      if (!this.controlValue.pdfOrientation) {
        this.controlValue.pdfOrientation = 'portrait';

        return false;
      }

      if (!this.controlValue.pdfContentWidth) {
        this.controlValue.pdfContentWidth = '800px';

        return false;
      }

      return true;
    },
    normalizeCurrency(val) {
      const value = val || 0;
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    },
    async getData() {
      try {
        this.appointmentId = this.$route.params.idappointment;
        this.patientId = this.$route.params.id;
        const response = await this.axios.get(`patient/social/details/${this.patientId}/${this.appointmentId}`);
        const { data } = response;
        if (data) {
          this.tableCars = data.car;
          this.evaluationId = response.data.id;
          this.haveData = true;
          const firstFamily = [];
          const familyCore = [];
          if (data.familyCore.length > 0) {
            data.familyCore.forEach((element) => {
              // eslint-disable-next-line max-len
              // eslint-disable-next-line no-unused-expressions, eqeqeq, no-param-reassign, no-self-assign
              element.occupation = element.occupation || 'Sin datos';
              // eslint-disable-next-line max-len
              // eslint-disable-next-line no-unused-expressions, eqeqeq, no-param-reassign, no-self-assign
              element.age === 0 ? (element.age = 'Sin datos') : (element.age = element.age);

              // ORDEN DE FAMILY CORE
              if (element.self === true) {
                firstFamily.push(element);
              } else {
                familyCore.push(element);
              }
            });
            if (familyCore.length > 0) {
              const familyCoreSort = familyCore.sort((a, b) => a.orderRegister - b.orderRegister);
              familyCoreSort.unshift(...firstFamily);
              data.familyCore = familyCoreSort;
            } else {
              data.familyCore = firstFamily;
            }
          }
          this.socioeva = {
            ...data,
            creator: {
              firstName: data['creator.firstName'],
              lastName: data['creator.lastName'],
            },
          };
          // await this.getCar();
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    normalizeBoolean(val) {
      return val ? 'SÍ' : 'NO';
    },
    async GetDate() {
      try {
        const response = await this.axios.get(`patient/social/getDateSocial/${this.patientId}/${this.appointmentId}`);
        if (response.data) {
          this.dateSocialAppointment = response.data[0].date;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async getPatient() {
      try {
        const request = await patientAPI.getPatientInfo(this.patientId);
        this.patientInfo = request;
        this.patientInfo.fullName = `${this.patientInfo.firstName} ${this.patientInfo.lastName}`;
      } catch (error) {
        // console.log(error);
      }
    },
    async getPatientPerfil() {
      try {
        const request = await patientAPI.getPatientProfile(this.patientId);
        this.patientProfile = request;
      } catch (error) {
        this.$notify({
          title: 'Información del paciente',
          type: 'error',
          message: 'Aún no se ha registrado información detallada del paciente',
        });
      }
    },
    // async getCar() {
    //   try {
    //     const request = await this.axios.get(`patient/newsocial/car/${this.evaluationId}`);
    //     console.log('request social', request);
    //     if (request.data) {
    //       const { data } = request;
    //       this.tableCars = data;
    //     }
    //   } catch (error) {
    //     this.$notify({
    //       title: 'Error',
    //       message: 'No se pudo obtener la información de los automóviles',
    //       type: 'error',
    //     });
    //   }
    // },
  },
  async created() {
    this.$loading();
    await this.getData();
    await this.GetDate();
    await this.getPatient();
    await this.getPatientPerfil();
    this.$loading().close();
  },
};
</script>

<style lang="scss">
.el-divider__text {
  padding: 0 5px!important;
}
</style>

<style lang="scss" scoped>
#html-p {
  line-height: 1rem;
  margin: 0px;
  padding: 0px;
  p {
    margin: 0;
    padding: 0;
    br {
      display: none;
    }
  }
  ol {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      br {
        display: none;
      }
    }
  }
}
.container {
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  .table-history-social{
    width: 100%;
    margin-left: 50px;
    border-collapse: collapse;
    border-spacing: 0;
    th, td {
      text-align: left;
      padding: 12px;
      font-size: 14px;
      color: rgb(53, 53, 53);
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
  .totales {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: rgb(53, 53, 53);

    span {
      margin-left: 10px;
      font-size: 18px;
    }
  }
  .sections {
      width: 60%;
      margin: 0px auto;

    .expences {
      .row {
        .title, .content {
          padding: 0px!important;
        }
        div:first-child {
          padding: 0 0 0 25px;
        }
      }
    }
    .section {
      padding: 5px 0 0 0;
      width: 100%;
      overflow-x: hidden;
      li {
        list-style-type: none;
      }
      .header-bathroom {
        display: flex;
        gap: 15px;
        .bathroom-info {
          color: #000;
          display: flex;
          flex-direction: column;
        }
      }
      .sec-title {
        .el-divider__text {
          font-size: 16px;
          font-weight: bold;
          color: #6a41bdca;
        }
      }
      .subtitle {
        font-size: 14px;
        margin: 0px;
        margin-left: 10px;
        margin-top: 15px;
        margin-bottom: 5px;
        font-weight: bold;
        color: #6a41bdca;
      }
      .content {
        white-space: pre-line;
        margin: 0px;
        margin-top: 2px;
        margin-left: 30px;
        color: #434343;
        padding: 0 0 0 25px;
      }
      .col {
        margin-left: 10px;
        .row {
          margin-left: 0px;
        }
        .title {
          font-weight: bold;
          color: #000000;
          margin: 0px;
          padding: 0 0 0 25px;

        }
        .content {
          color: #484848;
          margin: 0px;
          margin-top: 2px;
          margin-bottom: 10px;
          padding: 0 0 0 25px;
        }
      }
      .row {
        margin-left: 10px;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        div {
          margin-right: 20px;
          div {
            margin-right: 20px;
          }
        }
        .lh {
          height: 72px;
        }
        .lh2 {
          height: 30px;
        }
        .large-text {
          padding-right: 0px;
          max-width: 200px;
        }
        .title {
          font-weight: bold;
          color: #000000;
          margin: 0px;
          padding: 0 0 0 25px;
        }
        .content {
          color: #808C95;
          margin: 0px;
          margin-top: 2px;
          margin-bottom: 12px;
          padding: 0 0 0 25px;
        }
      }
    }
  }
  .no-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h3 {
      color: #808C95;
      font-size: 21px!important;
    }
  }
  .content-date{
    margin: auto;
    width:60%;
    display:flex;
    justify-content: space-between;

    img{
      margin: 20px 0 20px 0;
    }

    .medical-note{
      h3 {
        margin-top: 30px;
        font-size: 18px;
        text-align: right;
      }
    }
  }
}
</style>
<style lang="scss">
.main-container{
  height: auto !important;
}
</style>
