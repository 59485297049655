<template>
<div class="container">
  <div class="content-date">
    <div
      style="
        background-color: #6A41BD;
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        margin-left: 30px;
      "
    >
      <img
        src="../assets/login-bg.png"
        alt="logo Codet"
        style="width: 55px; height: 55px"
      >
    </div>
  <div class="medical-note">
    <h1>{{ $dateString(fecha) }}</h1>
  </div>
</div>
<div class="sections" v-if="haveData">
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        DATOS GENERALES DEL PACIENTE
      </el-divider>
    </p>
    <div class="row">
      <div>
        <p class="title">Nombre</p>
        <p class="content">{{ patientInfo.fullName }}</p>
      </div>
      <div>
        <p class="title">Sexo</p>
        <p class="content">{{ patientInfo.sex === 'Femenino' ? 'Mujer' : 'Hombre'  }}</p>
      </div>
      <div>
        <p class="title">Fecha de nacimiento</p>
        <p class="content">{{ patientInfo.birthdate || 'Sin registro'  }}</p>
      </div>
      <div>
        <p class="title">Edad</p>
        <p class="content">{{ $ageNumber(patientInfo.birthdate)  }}</p>
      </div>
      <div>
        <p class="title">Estado civil</p>
        <p class="content">{{ patientProfile.maritalStatus || 'Sin registro'}}</p>
      </div>
      <div>
        <p class="title">Ocupación</p>
        <p class="content">
          {{ patientProfile.occupation == null ? 'Sin registro' : patientProfile.occupation }}
        </p>
      </div>
    </div>
    <div class="row">
      <div>
        <p class="title">Lugar de nacimiento</p>
        <p class="content">{{ patientProfile.birthplace || 'Sin registro'}}</p>
      </div>
      <div>
        <p class="title">Domicilio</p>
        <p class="content">
          {{ patientProfile.address == null ? 'Sin registro' : patientProfile.address }},
          {{ patientProfile.city == null ? 'Sin registro' : patientProfile.city }},
          {{ patientProfile.state == null ? 'Sin registro' : patientProfile.state }},
          {{ patientProfile.country == null ? 'Sin registro' : patientProfile.country }}
        </p>
      </div>
    </div>
    <div class="row">
      <div>
        <p class="title">Teléfono</p>
        <p class="content">
          {{ patientInfo.phone == null ? 'Sin registro' : patientInfo.phone }}
        </p>
      </div>
      <div>
        <p class="title">Seguro médico</p>
        <p class="content">
          {{ patientProfile.affiliation == null ? 'No' : patientProfile.affiliation }}
        </p>
      </div>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        DATOS INFORMATIVOS DEL BENEFICIARIO
      </el-divider>
    </p>
    <div class="row">
      <div>
        <p class="title">
          Motivo de la evaluación
        </p>
        <p class="content">
          {{socioeva2.motivoEvaluacion}}
        </p>
      </div>
      <div>
        <p class="title">
          TIEMPO DE RESIDENCIA EN TIJUANA
        </p>
        <p class="content">
          {{socioeva2.residenceTimeAtTijuana}}
        </p>
      </div>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        LINEA DE BIENESTAR
      </el-divider>
    </p>
    <div class="row">
      <div>
        <p class="title">
          ESCOLARIDAD
        </p>
        <p class="content">
          {{socioeva2.academicDegree}}
        </p>
      </div>
      <div>
        <p class="title">
          CONDICIONES DE LA VIVIENDA
        </p>
        <p class="content">
          {{socioeva2.houseCondition}}
        </p>
      </div>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        CARACTERÍSTICAS DE LA VIVIENDA
      </el-divider>
    </p>
    <div class="row">
      <div>
        <p class="title">
          PAREDES
        </p>
        <p class="content">
          {{socioeva2.houseWalls}}
        </p>
      </div>
      <div>
        <p class="title">
          TECHO
        </p>
        <p class="content">
          {{socioeva2.houseRoof}}
        </p>
      </div>
      <div>
        <p class="title">
          PISO
        </p>
        <p class="content">
          {{socioeva2.houseFloor}}
        </p>
      </div>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        CUARTOS DE LA VIVIENDA
      </el-divider>
    </p>
    <div class="row">
      <div>
        <p class="title">
          CUARTOS
        </p>
        <p class="content">
          {{rooms}}
        </p>
      </div>
      <div v-if="socioeva2.bathroom.length > 0">
        <table
          class="table-history-social"
          style="width: 400px; margin-top: -12px;">
          <tr>
            <th>Baño</th>
            <th>Pozo</th>
            <th>Regadera</th>
            <th>Fosa</th>
            <th>WC</th>
          </tr>
          <tr v-for="(bath, idx) in socioeva2.bathroom" :key="idx" >
            <td>{{ idx + 1 }}</td>
            <td>{{bath.pozo ? 'Sí' : 'No'}}</td>
            <td>{{bath.regadera ? 'Sí' : 'No'}}</td>
            <td>{{bath.fosa ? 'Sí' : 'No'}}</td>
            <td>{{bath.wc ? 'Sí' : 'No'}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        SERVICIOS DE LA VIVIENDA
      </el-divider>
    </p>
    <p class="content" style="font-size: 12px;">
      {{services}}
    </p>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        MUEBLES Y ELECTRODOMÉSTICOS
      </el-divider>
    </p>
    <p class="content" style="font-size: 12px;">
      {{houseStuff}}
    </p>
  </div>
  <div class="section html2pdf__page-break">
    <p class="sec-title">
      <el-divider content-position="left" style="font-size: 12px;">
        CUENTA CON AUTO PROPIO
      </el-divider>
    </p>
    <table class="table-history-social" v-if="socioeva.cars.length > 0">
      <tr>
        <th>Automóvil</th>
        <th>Modelo</th>
        <th>Año</th>
        <th>Placas</th>
      </tr>
      <tr v-for="(car, idx) in socioeva.cars" :key="idx" >
        <td>{{ idx + 1 }}</td>
        <td>{{ car.model }}</td>
        <td>{{ car.year }}</td>
        <td>{{ car.plates }}</td>
      </tr>
    </table>
    <p v-else class="content" style="font-size: 12px;">NO CUENTA CON AUTOMÓVIL</p>
  </div>
  <div class="section" style="margin-top: 50px;">
    <p class="sec-title">
      <el-divider content-position="left">INGRESOS MENSUALES EN PROMEDIO</el-divider>
    </p>
    <p class="sub"/>
    <table class="table-history-social">
      <tr>
        <th>Nombre</th>
        <th>Parentesco</th>
        <th>Ocupación</th>
        <th>Edad</th>
        <th>Ingreso mensual promedio</th>
      </tr>
      <tr v-for="(contributor, idx) in socioeva2.familyCore" :key="idx">
        <td style="font-size: 10px;">{{ contributor.fullname }}</td>
        <td style="font-size: 10px;">{{ contributor.relationship }}</td>
        <td style="font-size: 10px;">{{ contributor.occupation }}</td>
        <td style="font-size: 10px;">{{
          contributor.age === 'Sin datos' ? $ageNumber(contributor.birthdate) : contributor.age
        }}
        </td>
        <td style="font-size: 10px;">{{ normalizeCurrency(contributor.monthlyContribution) }}</td>
      </tr>
    </table>
    <div class="row">
      <p class="totales">
        TOTAL DE INGRESOS: <span>{{ normalizeCurrency(totalContribution) }}</span>
      </p>
    </div>
  </div>
  <div class="section" >
    <p class="sec-title">
      <el-divider content-position="left">
        EGRESOS MENSUALES EN PROMEDIO
      </el-divider>
    </p>
    <table class="table-history-social">
      <tr>
        <th>Agua</th>
        <th>Luz</th>
        <th>Teléfono / internet</th>
        <th>Gas</th>
        <th>Alimentación</th>
        <th>Limpieza del hogar</th>
      </tr>
      <tr>
        <td>{{ normalizeCurrency(socioeva2.expenseWater) }}</td>
        <td>{{normalizeCurrency(socioeva2.expenseLight)}}</td>
        <td>{{ normalizeCurrency(socioeva2.expensePhone) }}</td>
        <td>{{ normalizeCurrency(socioeva2.expenseGas) }}</td>
        <td>{{ normalizeCurrency(socioeva2.expenseFood) }}</td>
        <td>{{normalizeCurrency(socioeva2.expenseHouseSupplies)}}</td>
        <td/>
        <td/>
      </tr>
    </table>
    <table class="table-history-social" style="margin-top: 20px;">
      <tr>
        <th>Renta</th>
        <th>Celular</th>
        <th>Transporte</th>
        <th>Salud</th>
        <th>Educación</th>
        <th>Tarjeta de crédito</th>
        <th>Otros</th>
      </tr>
      <tr>
        <td>{{ normalizeCurrency(socioeva2.expenseRent) }}</td>
        <td>{{ normalizeCurrency(socioeva2.expenseCellPhone) }}</td>
        <td>{{normalizeCurrency(socioeva2.expenseTransport)}}</td>
        <td>{{ normalizeCurrency(socioeva2.expenseHealth) }}</td>
        <td>{{ normalizeCurrency(socioeva2.expenseEducation) }}</td>
        <td>{{normalizeCurrency(socioeva2.expenseCreditCard)}}</td>
        <td>{{ normalizeCurrency(socioeva2.expenseOther) }}</td>
        <td/>
        <td/>
      </tr>
    </table>
    <div>
      <p class="totales"  style="margin-bottom: 1px;">
        TOTAL DE EGRESOS: <span>{{normalizeCurrency(totalExpenses)}}</span>
      </p>
      <p class="totales"  style="margin-top: 1px;">
        REMANENTE O DEUDA: <span>{{normalizeCurrency(totalContribution - totalExpenses)}}</span>
      </p>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        CUALITATIVO
      </el-divider>
    </p>
    <div style="margin-left: 40px;" class="html2pdf__page-break">
      <p class="subtitle">
        DESCRIPCIÓN DE LA SITUACIÓN FAMILIAR
      </p>
      <p class="content" style="line-height: 14px; font-size: 12px;">
        <span v-html="socioeva2.familySituationDescription" />
      </p>
    </div>
    <div style="margin-left: 40px;margin-top: 50px;" >
      <p class="subtitle">
        COMENTARIOS DE LA TRABAJADORA SOCIAL
      </p>
      <p class="content" style="line-height: 14px; font-size: 12px;">
        <span v-html="socioeva2.comments"/>
      </p>
    </div>
  </div>
  <div class="section">
    <p class="sec-title">
      <el-divider content-position="left">
        EVALUACIÓN DE LA TRABAJADORA SOCIAL
      </el-divider>
    </p>
    <p class="content">
      <span class="subtitle">Clasificación: </span>
      <span style="font-size: 12px;">{{socioeva2.classification}}.</span>
    </p>
    <p class="content">
      <span class="subtitle">Resultado: </span>
      <span style="font-size: 12px;">{{socioeva2.evaluationResult}}.</span>
    </p>
  </div>
  <div class="section"  v-if="socioeva2.surgery.length > 0">
    <p class="sec-title">
      <el-divider content-position="left">
        CIRUGÍAS
      </el-divider>
    </p>
    <table class="table-history-social" style="margin-top: 20px;">
      <tr>
        <th>Ojo</th>
        <th>Especialidad</th>
        <th>Tipo</th>
        <th>Costo</th>
        <th>Precio</th>
        <th>Moneda</th>
      </tr>
      <tr v-for="surgery in socioeva2.surgery" :key="surgery.id">
        <td>{{ surgery.eye == 'RIGHT' ? 'DERECHO' : 'IZQUIERDO' }}</td>
        <td>{{ surgery.specialty }}</td>
        <td>{{ surgery.type }}</td>
        <td>{{ surgery.price === 0 ? 'Sin costo' : 'Con costo' }}</td>
        <td>{{ surgery.price || '$0' }}</td>
        <td>{{ surgery.currency || 'Sin costo' }}</td>
      </tr>
    </table>
  </div>
  <div
    class="section"
    style="
      width: 100%;
      text-align: center;
      margin-top:150px;
      display: flex;
      flex-direction: column;
      justify-content: center;"
  >
    <p class="content" style="font-size: 12px;">
      _________________________________________________<br><br>
      <span style="font-weight: bold;">FIRMA DE LA TRABAJADORA SOCIAL</span><br>
      {{ socioeva2.creator ? socioeva2.creator.firstName : '' }}
      {{ socioeva2.creator ? socioeva2.creator.lastName : '' }}
    </p>
  </div>
</div>
<div v-else class="no-data" :style="{height: height}">
  <h3>Aun no se lleva a cabo la evaluación</h3>
</div>
</div>
</template>

<script>
// import patientAPI from '../API/comunidad/patient';

export default {
  name: 'SocioEconomic',
  props: {
    patientId: {
      type: Number,
    },
    fecha: {
      type: String,
    },
    patientInfo: {
      type: Array,
    },
    patientProfile: {
      type: Array,
    },
    socioeva2: {
      type: Array,
    },
  },
  data() {
    return {
      appointmentId: '',
      socioeva: {
        familyCore: [],
        familyWide: [],
        cars: [],
      },
      haveData: false,
      height: !this.haveData ? '250px' : '450px',
    };
  },
  computed: {
    totalContribution() {
      let acct = 0;
      for (let i = 0; i < this.socioeva2.familyCore.length; i++) {
        const element = this.socioeva2.familyCore[i];
        acct += parseFloat(element?.monthlyContribution || 0);
      }
      for (let i = 0; i < this.socioeva2.familyWide.length; i++) {
        const element = this.socioeva2.familyWide[i];
        acct += parseFloat(element?.monthlyContribution || 0)
          + parseFloat(element?.otherContribution || 0);
      }
      return acct;
    },
    totalExpenses() {
      let acct = 0;
      const keys = [
        'expenseWater',
        'expenseLight',
        'expensePhone',
        'expenseCellPhone',
        'expenseGas',
        'expenseFood',
        'expenseHouseSupplies',
        'expenseHealth',
        'expenseEducation',
        'expenseRent',
        'expenseTransport',
        'expenseCreditCard',
        'expenseOther',
      ];
      for (let i = 0; i < keys.length; i++) {
        const element = this.socioeva2[keys[i]];
        if (element) {
          acct += parseFloat(element);
        }
      }
      return acct;
    },
    services() {
      const services = [];
      const servicesMap = {
        serviceLight: 'Luz',
        serviceSewerSystem: 'Drenaje y alcantarillado',
        serviceCableTv: 'Televisión por cable',
        servicePhone: 'Teléfono fijo',
        serviceInternet: 'Internet',
        serviceAsphaltStreet: 'Pavimiento en las calles',
        servicePublicWater: 'Agua Pública',
        serviceOther: 'Otro',
      };
      const keys = Object.keys(servicesMap);
      keys.forEach((key) => {
        if (this.socioeva2[key]) {
          services.push(servicesMap[key]);
        }
      });
      return services.join(', ') || 'Sin servicios';
    },
    rooms() {
      const rooms = [];
      const roomsMap = {
        houseRoomsLivingRoom: 'Sala',
        houseRoomsDiningRoom: 'Comedor',
        houseRoomsKitchen: 'Cocina',
        houseRoomsBedroom: 'Recámara',
        houseRoomsStudyingRoom: 'Estudio',
        houseRoomsCourtyard: 'Patio',
        houseRoomsOther: 'Otro',
      };
      const keys = Object.keys(roomsMap);
      keys.forEach((key) => {
        if (this.socioeva2[key]) {
          let room = roomsMap[key];
          if (key === 'houseRoomsBedroom') {
            const value = this.socioeva2.houseRoomsBedroom;
            const suffix = value > 1 ? 's' : '';
            room = `${value} ${room}${suffix}`;
          }
          rooms.push(room);
        }
      });
      return rooms.join(', ') || 'Sin datos';
    },
    houseStuff() {
      const houseStuff = [];
      const houseStuffMap = {
        houseStuffTV: 'Televisión',
        houseStuffFridge: 'Refrigerador',
        houseStuffStove: 'Estufa',
        houseStuffDishWasher: 'Lava platos',
        houseStuffMicrowaveOven: 'Horno de Microondas',
        houseStuffBlender: 'Licuadora',
        houseStuffWashingMachine: 'Lavadora',
        houseStuffDryer: 'Secadora',
        houseStuffComputer: 'Computadora o Ipad',
        houseStuffDiningRoomSet: 'Comedor',
        houseStuffLivingRoomSet: 'Sala',
        houseStuffBedroomSet: 'Recámara',
      };
      const keys = Object.keys(houseStuffMap);
      keys.forEach((key) => {
        if (this.socioeva2[key]) {
          houseStuff.push(houseStuffMap[key]);
        }
      });
      return houseStuff.join(', ') || 'Sin datos';
    },
  },
  methods: {
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        this.controlValue.pdfQuality = 2;

        return false;
      }

      if (!this.controlValue.paginateElementsByHeight) {
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }

      const paperSizes = 'a4';

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        this.controlValue.pdfFormat = 'a4';

        return false;
      }

      if (!this.controlValue.pdfOrientation) {
        this.controlValue.pdfOrientation = 'portrait';

        return false;
      }

      if (!this.controlValue.pdfContentWidth) {
        this.controlValue.pdfContentWidth = '800px';

        return false;
      }

      return true;
    },
    normalizeCurrency(val) {
      const value = val || 0;
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    },
    async getData() {
      try {
        this.appointmentId = this.$route.params.idappointment;
        const response = await this.axios.get(`patient/social/details/${this.patientId}/${this.appointmentId}`);
        const { data } = response;
        if (data) {
          this.socioeva.cars = data.car || [];
          this.haveData = true;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    normalizeBoolean(val) {
      return val ? 'SÍ' : 'NO';
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.el-divider__text {
  padding: 0 5px!important;
}
</style>

<style lang="scss">
.container {
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  margin-top: 20px;
  .table-history-social{
    width: 700px;
    margin-left: 50px;
    border-collapse: collapse;
    border-spacing: 0;
    th, td {
      text-align: left;
      padding: 12px;
      font-size: 12px;
      color: rgb(53, 53, 53);
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
  .totales {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
    color: rgb(53, 53, 53);

    span {
      margin-left: 10px;
      font-size: 14px;
    }
  }
  .sections {
      // width: 60%;
      margin: 0px auto;

    .expences {
      .row {
        .title, .content {
          padding: 0px!important;
        }
        div:first-child {
          padding: 0 0 0 25px;
        }
      }
    }
    .section {
      padding: 5px 0 0 0;
      width: 100%;
      overflow-x: hidden;
      li {
        list-style-type: none;
      }
      .header-bathroom {
        display: flex;
        gap: 15px;
        .bathroom-info {
          color: #000;
          display: flex;
          flex-direction: column;
        }
      }
      .container-card-cars {
        margin-left: 30px;
        border-radius: 12px;
        font-size: 14px;
      }
      .sec-title {
        .el-divider__text {
          font-size: 14px;
          font-weight: bold;
          color: #6a41bdca;
        }
      }
      .subtitle {
        font-size: 14px;
        margin: 0px;
        margin-left: 10px;
        margin-top: 15px;
        margin-bottom: 5px;
        font-weight: bold;
        color: #6a41bdca;
      }
      .content {
        white-space: pre-line;
        margin: 0px;
        margin-top: 2px;
        margin-left: 30px;
        color: #000000;
        padding: 0 0 0 20px;
      }
      .col {
        margin-left: 10px;
        .row {
          margin-left: 0px;
        }
        .title {
          font-weight: bold;
          color: #000000;
          margin: 0px;
          padding: 0 0 0 25px;

        }
        .content {
          color: #000000;
          margin: 0px;
          margin-top: 2px;
          margin-bottom: 10px;
          padding: 0 0 0 25px;
        }
      }
      .row {
        margin-left: 10px;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        div {
          margin-right: 20px;
          div {
            margin-right: 20px;
          }
        }
        .lh {
          height: 72px;
        }
        .lh2 {
          height: 30px;
        }
        .large-text {
          padding-right: 0px;
          max-width: 200px;
        }
        .title {
          font-weight: bold;
          font-size: 12px;
          color: #000000;
          margin: 0px;
          padding: 0 0 0 25px;
        }
        .content {
          color: #000000;
          font-size: 12px;
          margin: 0px;
          margin-top: 2px;
          margin-bottom: 12px;
          padding: 0 0 0 25px;
        }
      }
    }
  }
  .no-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h3 {
      color: #808C95;
      font-size: 21px!important;
    }
  }
  .content-date{
    margin: auto;
    width:100%;
    display:flex;
    justify-content: space-between;

    img{
      margin: 20px 0 20px 0;
    }

    .medical-note{
      h1 {
        margin-top: 30px;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}
</style>
<style lang="scss">
// .main-container{
//   height: auto !important;
// }
</style>
