<template>
<div class="div-oftaHistory" v-if="appointmentId">
  <br>
  <Title2
    v-if="appointmentId"
    title="HISTORIAL DEL PACIENTE - TRABAJO SOCIAL - "
    label="Datos generales del paciente"
    :appointment="appointmentId"
    appointmentType="social"
    :patientId="patientId"
  />
  <history />
</div>
</template>

<script>
import history from '../../components/HistorySocialComponent';
import Title2 from '../../components/Title2';

export default {
  data() {
    return {
      data: [],
      appointmentId: null,
      patientId: null,
    };
  },
  components: {
    history,
    Title2,
  },
  created() {
    this.appointmentId = this.$route.params.idappointment;
    this.patientId = this.$route.params.patientId;
  },
};
</script>

<style scoped>
.div-oftaHistory{
  min-height: auto;
  margin: auto;
  background-color: #fff;
}
</style>
